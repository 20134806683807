import type { FC } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import BufferCopy from 'components/betting/BufferCopy';
import { useFormatTimeAndDate } from 'hooks';
import { useBetItemData } from '../BetItemProvider';

const BetHeader: FC = () => {
    const { createdAt, betId } = useBetItemData();

    const { date, time } = useFormatTimeAndDate({
        timeStamp: createdAt,
        formats: {
            dateFormat: 'MMM dd',
        },
    });

    const t = useTranslations('BetsHistory.gincms.bets_history');

    return (
        <div className="flex h-10 items-center justify-between px-4 text-grey-500 typo-sm-1">
            {date} / {time}
            <BufferCopy
                copyData={betId}
                text={`${t('bet_event.bet')} ID`}
                checkIconCn={cn('fill-green-700')}
                copyIconCn={cn('fill-grey-500')}
            />
        </div>
    );
};

export default BetHeader;
