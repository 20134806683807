import type { FC } from 'react';
import { memo, useContext } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import { isBettingGlobalRefundTitle } from 'translations/utils';

import PackIcon, { IconsPack } from 'components/PackIcon';
import { useGetRelevantCurrency } from 'layouts/MainLayout/getRelevantCurrency.cms';
import { Feature, useFeatureFlags } from 'services/features';
import { BetType } from 'types/gql.bet';
import { RefundStatus } from '../../../constants';
import { useBetItemData } from '../../BetItemProvider';
import { BetFooterContext } from '../context';

const BetFooterInfo: FC = () => {
    const {
        betType,
        odds,
        systemSizes,
        refundInfo,
        totals,
        freebetId,
        insurance,
    } = useBetItemData();
    const { data } = useGetRelevantCurrency(() => ({
        pagePropsKey: 'relevantCurrency',
    }));
    const { isCashOutOpen } = useContext(BetFooterContext);
    const { features } = useFeatureFlags();

    const { refundTitle, refund, refundStatus } = refundInfo;
    const isRefundStatusLoss = refundStatus === RefundStatus.Loss;
    const { totalStake } = totals;

    const isInsurance =
        !!insurance && features.isEnabled(Feature.BetslipInsurance);

    const tBettingGlobal = useTranslations('betting-global.global');
    const tBettingBetshistory = useTranslations(
        'betting-betshistory.betsHistory.BetFooter'
    );

    const oddsSize = odds.length;
    const typeName = `${tBettingGlobal(betType)} ${
        betType === BetType.System
            ? systemSizes.join(`/${oddsSize}, `).concat(`/${oddsSize}`)
            : ''
    }`;

    const textColor =
        isRefundStatusLoss && !isInsurance ? 'text-grey-700' : 'text-grey-500';

    return (
        <>
            <div
                className={cn(
                    'flex items-center justify-between px-4 pb-2 typo-sm-1'
                )}
            >
                <div className="flex items-center">
                    <span className={cn(isInsurance && '!text-grey-500')}>
                        {typeName}
                    </span>
                    {freebetId && (
                        <PackIcon
                            id="promo-freebets"
                            pack={IconsPack.SpriteIcons}
                            className={cn(
                                'ml-1',
                                isRefundStatusLoss
                                    ? 'fill-grey-700'
                                    : 'fill-turquoise-500'
                            )}
                        />
                    )}
                    {isInsurance && (
                        <PackIcon
                            id="insurance"
                            pack={IconsPack.SpriteIcons}
                            className={cn(
                                'ml-1',
                                refundStatus === RefundStatus.Win
                                    ? 'fill-grey-700'
                                    : 'fill-green-700'
                            )}
                        />
                    )}
                </div>
                <div
                    className={cn('uppercase', {
                        'text-turquoise-500': freebetId,
                        '!text-green-700': isInsurance,
                        '!text-grey-700': isRefundStatusLoss,
                    })}
                >
                    <span>{`${totalStake} ${data?.currency}`}</span>
                </div>
            </div>

            {!isCashOutOpen && (
                <div
                    className={cn(
                        'flex items-center justify-between px-4 pb-3 typo-sm-1'
                    )}
                >
                    <div className={cn('capitalize', textColor)}>
                        {isBettingGlobalRefundTitle(refundTitle)
                            ? tBettingGlobal(refundTitle)
                            : tBettingBetshistory(refundTitle)}
                    </div>
                    <div
                        className={cn(
                            'uppercase',
                            isRefundStatusLoss && !isInsurance
                                ? 'text-grey-700'
                                : 'text-green-700'
                        )}
                    >
                        {`${
                            isRefundStatusLoss && !isInsurance ? 0 : refund
                        } ${data?.currency}`}
                    </div>
                </div>
            )}
        </>
    );
};

export default memo(BetFooterInfo);
