import { first, last } from 'lodash';

import { CashOutRestrictions, RestrictionType } from 'types/gql.bet';

export const getRestrictionMessage = (
    restrictions: CashOutRestrictions[],
    bettingStaticMessages: (key: any) => string
): string[] => {
    const msgs: string[] = [];

    const isUnavailableCashout = restrictions.some((restriction): boolean => {
        if (!('type' in restriction)) return false;

        switch (restriction.type) {
            case RestrictionType.BetCashOutSelectionsMismatch:
            case RestrictionType.BaselineSelectionExistence:
            case RestrictionType.CashOutAmountLimit:
            case RestrictionType.MarketStatus:
            case RestrictionType.SportEventStatus:
            case RestrictionType.SportEventBetStop:
            case RestrictionType.CashOutOrderStatus: {
                msgs.push(
                    bettingStaticMessages(
                        'cashout.Cashout.temporarilyUnavailable'
                    )
                );

                return false;
            }

            case RestrictionType.CashOutUnavailable:
            case RestrictionType.BetProvider:
            case RestrictionType.BetStatus:
            case RestrictionType.OddStatus:
            case RestrictionType.MarketDefective:
            case RestrictionType.SportEventExistence:
            case RestrictionType.OddExistence:
            case RestrictionType.MarketExistence: {
                msgs.push(
                    bettingStaticMessages('cashout.CashOut.cashoutNotAvailable')
                );

                return true;
            }

            case RestrictionType.SelectionValue:
            case RestrictionType.CashOutRefundAmount: {
                msgs.push(
                    bettingStaticMessages('cashout.Cashout.cashOutRefundAmount')
                );

                return false;
            }

            default: {
                return false;
            }
        }
    });

    const msg = isUnavailableCashout ? last(msgs) : first(msgs);

    return msg ? [msg] : [];
};
