import { FC, memo } from 'react';

import { useOnUpdateSportEvent } from 'gql/betting/subscriptions/onUpdateSportEvent.bet';
import idPathParser from 'utils/idPathParser';

interface Props {
    path: string;
}

const BetItemSportEventSubscription: FC<Props> = ({ path }) => {
    const { sportEventId, marketId } = idPathParser.split('Odd', path);

    useOnUpdateSportEvent({
        variables: { sportEventId, marketIds: [marketId], skipMarkets: true },
    });

    return null;
};

export default memo(BetItemSportEventSubscription);
