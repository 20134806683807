import * as GQL from 'types/gql.bet';

import * as Operations from './placeCashOutOrder.bet.gql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'services/apollo/betting';
const defaultOptions = {} as const;

export type PlaceCashOutOrderMutationFn = Apollo.MutationFunction<
    GQL.PlaceCashOutOrder,
    GQL.PlaceCashOutOrderVariables
>;

/**
 * __usePlaceCashOutOrder__
 *
 * To run a mutation, you first call `usePlaceCashOutOrder` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceCashOutOrder` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeCashOutOrder, { data, loading, error }] = usePlaceCashOutOrder({
 *   variables: {
 *      betId: // value for 'betId'
 *      amount: // value for 'amount'
 *      refundAmount: // value for 'refundAmount'
 *      selections: // value for 'selections'
 *   },
 * });
 */
export function usePlaceCashOutOrder(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQL.PlaceCashOutOrder,
        GQL.PlaceCashOutOrderVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        GQL.PlaceCashOutOrder,
        GQL.PlaceCashOutOrderVariables
    >(Operations.PlaceCashOutOrder, options);
}
export type PlaceCashOutOrderHookResult = ReturnType<
    typeof usePlaceCashOutOrder
>;
export type PlaceCashOutOrderMutationResult =
    Apollo.MutationResult<GQL.PlaceCashOutOrder>;
export type PlaceCashOutOrderMutationOptions = Apollo.BaseMutationOptions<
    GQL.PlaceCashOutOrder,
    GQL.PlaceCashOutOrderVariables
>;
