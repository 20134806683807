import type { FC } from 'react';
import { memo, useMemo } from 'react';
import cn from 'classnames';
import { floor } from 'lodash';
import { useTranslations } from 'next-intl';

import Button from 'components/Button';
import { PlaceCashOutSelection } from 'types/gql.bet';
import Footer from './Footer';
import Info from './Info';
import useCashOut from './useCashOut';

interface Props {
    betId: string;
    amount: number;
    selections: PlaceCashOutSelection[];
}

const CashOut: FC<Props> = ({ betId, amount, selections }) => {
    const {
        maxRefundAmount,
        handleToggleCashout,
        placeCashOut,
        isOpen,
        isLoading,
        isAccepted,
        isDeclined,
        errors,
        isAmountChanged,
        currencyCode,
    } = useCashOut({
        betId,
        amount,
        selections,
    });

    const t = useTranslations('BetsHistory.gincms.bets_history.cashout');

    const buttonInfoElement = useMemo(
        () => (
            <>
                <span className="mr-1 capitalize">{t('cashout_btn')}</span>
                <span className="capitalize">
                    {`${floor(Number(maxRefundAmount), 2)} ${currencyCode}`}
                </span>
            </>
        ),
        [maxRefundAmount, currencyCode, t]
    );

    if (maxRefundAmount === null || amount === null || (!isOpen && isDeclined))
        return null;

    if (!isOpen) {
        return (
            <div className="flex flex-col px-4 pb-4">
                <Button
                    isLoading={isLoading}
                    size={Button.Size.Medium}
                    color={Button.Color.Orange}
                    onClick={handleToggleCashout}
                >
                    {buttonInfoElement}
                </Button>
            </div>
        );
    }

    return (
        <div
            className={cn(
                'flex flex-col gap-2 border-y-default border-surface-dark bg-grey-900 px-4 pb-2 pt-3'
            )}
        >
            <Info maxRefundAmount={maxRefundAmount} currency={currencyCode} />
            {!isAccepted && (
                <>
                    <Button
                        size={Button.Size.Medium}
                        color={Button.Color.Surface}
                        onClick={handleToggleCashout}
                    >
                        <span className="capitalize">{t('cancel')}</span>
                    </Button>

                    <Button
                        isLoading={isLoading}
                        size={Button.Size.Medium}
                        color={Button.Color.Green}
                        onClick={isDeclined ? undefined : placeCashOut}
                        className={
                            isDeclined
                                ? cn(
                                      'cursor-default !bg-surface-middle !text-grey-700'
                                  )
                                : undefined
                        }
                    >
                        {buttonInfoElement}
                    </Button>
                </>
            )}
            <Footer
                isAccepted={isAccepted}
                isAmountChanged={isAmountChanged}
                isDeclined={isDeclined}
                errors={errors}
                maxRefundAmount={maxRefundAmount}
                currency={currencyCode}
            />
        </div>
    );
};

export default memo(CashOut);
