import { useEffect, useRef } from 'react';
import { useTranslations } from 'next-intl';

import { BetTypeTabs } from 'components/betting/BetsHistory/constants';
import { CashOutOrderStatusCode, PlaceCashOutSelection } from 'types/gql.bet';
import { betTypeVar } from '../BetsHistory/betHistoryState';
import useCashOutBase from './useCashOutBase';
import { getRestrictionMessage } from './utils';

const successInterval = 3000;

interface Input {
    betId: string;
    amount: number;
    selections: PlaceCashOutSelection[];
}

interface Output {
    maxRefundAmount: number | null;
    isOpen: boolean;
    handleToggleCashout: VoidFunction;
    placeCashOut: VoidFunction;
    isLoading: boolean;
    isAccepted: boolean;
    isDeclined: boolean;
    errors: string[];
    isAmountChanged: boolean;
    currencyCode: string;
}

const useCashOut = ({ betId, amount, selections }: Input): Output => {
    const t = useTranslations('betting-cashout');

    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const {
        isOpen,
        handleToggleCashout,
        placeCashOut,
        isLoading,
        status,
        maxRefundAmount,
        restrictions,
        isAmountChanged,
        currencyCode,
    } = useCashOutBase({
        betId,
        amount,
        selections,
    });

    const isAccepted = status === CashOutOrderStatusCode.Accepted;
    const isPending = status === CashOutOrderStatusCode.Pending;
    const isDeclined = status === CashOutOrderStatusCode.Declined;
    const errors = getRestrictionMessage(restrictions, t);

    const isCashOutEnabled = !isAccepted && !isDeclined;

    useEffect(() => {
        if (isAccepted) {
            timerRef.current = setTimeout(() => {
                betTypeVar(BetTypeTabs.Settled);
            }, successInterval);
        }

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [isAccepted]);

    return {
        maxRefundAmount: isDeclined ? 0 : maxRefundAmount,
        isOpen,
        handleToggleCashout,
        placeCashOut,
        isLoading: isLoading || isPending,
        isAccepted,
        isDeclined:
            !isAccepted &&
            (isDeclined || errors.length !== 0 || maxRefundAmount === 0),
        errors,
        isAmountChanged: isCashOutEnabled && isAmountChanged,
        currencyCode,
    };
};

export default useCashOut;
