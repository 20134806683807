import type { FC } from 'react';
import cn from 'classnames';

import { CashOutProvider } from 'components/betting/CashOut';
import BetFooterInfo from './BetFooterInfo';
import BetFooterProvider from './context';

interface Props {
    isCashouted: boolean;
}

const BetFooter: FC<Props> = ({ isCashouted }) => {
    return (
        <BetFooterProvider>
            <div className={cn('pt-3')}>
                <BetFooterInfo />
                {isCashouted && <CashOutProvider />}
            </div>
        </BetFooterProvider>
    );
};

export default BetFooter;
