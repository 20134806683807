import React from 'react';
import { useTranslations } from 'next-intl';

import PackIcon, { IconsPack, SvgIconSize } from 'components/PackIcon';

interface Props {
    errors: string[];
}

const Error: React.FC<Props> = ({ errors }) => {
    const t = useTranslations('betting-cashout.cashout.Cashout');

    return (
        <div className="flex gap-1 break-words rounded-[4px] border-default border-red-300 p-2 text-red-300 typo-sm-1">
            <PackIcon
                id="warning"
                pack={IconsPack.SpriteIcons}
                size={SvgIconSize.WH_4}
                className="fill-red-300"
            />
            <div className="flex flex-1 items-center">
                {errors.length ? (
                    errors.map((message) => (
                        <div key={message} className="w-full">
                            {message}
                        </div>
                    ))
                ) : (
                    <div className="w-full">{t('temporarilyUnavailable')}</div>
                )}
            </div>
        </div>
    );
};

export default Error;
