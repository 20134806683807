import { useCallback, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import { checkValueInObj } from 'utils';
import { betTypeVar } from '../betHistoryState';
import { BetTypeTabs } from '../constants';

interface Output {
    betTypeTabs: BetTypeTabs;

    handleChangeBetType: (tab: string) => VoidFunction;
}

const useBetListHeader = (): Output => {
    const betTypeTabs = useReactiveVar(betTypeVar);

    const handleChangeBetType = useCallback(
        (tab: string) => () => {
            if (!checkValueInObj(BetTypeTabs, tab)) return;

            betTypeVar(tab);
        },
        []
    );

    useEffect(
        () => () => {
            if (betTypeTabs !== BetTypeTabs.Settled) return;

            betTypeVar(BetTypeTabs.Unsettled);
        },
        [betTypeTabs]
    );

    return {
        betTypeTabs,
        handleChangeBetType,
    };
};

export default useBetListHeader;
