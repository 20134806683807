import { FC } from 'react';

import {
    AnalyticAttrs,
    AnalyticAttrsCtx,
    Category,
} from 'services/GoogleAnalytics';
import SportEventRow from '../../SportEventRow/SportEventRow';
import useRecommendation from './useRecommendation';

const ANALYTIC: AnalyticAttrs = {
    'data-category': Category.BetHistoryRecommendationBlockBet,
};

const Recommendation: FC = () => {
    const sportEvent = useRecommendation();

    if (!sportEvent) return null;

    const { id, fixture, streams, markets, slug, meta, version } = sportEvent;

    return (
        <AnalyticAttrsCtx.Provider value={ANALYTIC}>
            <div className="my-2 flex-row border-default border-khaki-700 align-middle">
                <SportEventRow
                    sportEventId={id}
                    fixture={fixture}
                    streams={streams}
                    markets={markets}
                    slug={slug}
                    meta={meta}
                    version={version}
                    isResizable={false}
                />
            </div>
        </AnalyticAttrsCtx.Provider>
    );
};

export default Recommendation;
