import React from 'react';
import { floor } from 'lodash';
import { useTranslations } from 'next-intl';

import PackIcon, { IconsPack, SvgIconSize } from 'components/PackIcon';

interface Props {
    maxRefundAmount: number;
    currency: string;
}

const Success: React.FC<Props> = ({ maxRefundAmount, currency }) => {
    const t = useTranslations('betting-cashout.cashout.CashOut');

    const refundAmount = floor(maxRefundAmount, 2);

    return (
        <div className="flex gap-1 break-words rounded-[4px] border-default border-green-700 px-2 py-3 text-green-700 typo-sm-1">
            <PackIcon
                id="success"
                pack={IconsPack.SpriteIcons}
                size={SvgIconSize.WH_4}
                className="fill-green-700"
            />
            <div className="flex-1">
                <div className="mb-2 typo-sm-2">{t('acceptStatusTitle')}</div>
                <div className="typo-sm-1">
                    {t('acceptStatusMsg', {
                        refundAmount: `${refundAmount} ${currency}`,
                    })}
                </div>
            </div>
        </div>
    );
};

export default Success;
