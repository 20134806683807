import type { FC } from 'react';
import cn from 'classnames';
import { map } from 'lodash';
import { useTranslations } from 'next-intl';

import { BetTypeTabs } from '../constants';
import useBetListHeader from './useBetListHeader';

const TABS: Record<
    BetTypeTabs,
    | 'gincms.bets_history.bet_event.unsettled'
    | 'gincms.bets_history.bet_event.settled'
> = {
    [BetTypeTabs.Unsettled]: 'gincms.bets_history.bet_event.unsettled',
    [BetTypeTabs.Settled]: 'gincms.bets_history.bet_event.settled',
};

const BetListHeader: FC = () => {
    const { betTypeTabs, handleChangeBetType } = useBetListHeader();

    const t = useTranslations('BetsHistory');

    return (
        <div className="flex h-10 rounded-default px-2 text-grey-700">
            {map(TABS, (msg, tab) => (
                <div
                    key={tab}
                    className={cn(
                        'flex w-full grow cursor-pointer items-center justify-center capitalize typo-sm-1',
                        betTypeTabs === tab
                            ? 'bg-surface-light'
                            : 'bg-surface-middle',
                        {
                            'text-primary-white': betTypeTabs === tab,
                        }
                    )}
                    onClick={handleChangeBetType(tab)}
                    data-test={`bet-list-header bet-list-header--${betTypeTabs}`}
                >
                    {t(msg)}
                </div>
            ))}
        </div>
    );
};

export default BetListHeader;
