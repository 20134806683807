import { useCallback, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { reduce, uniq } from 'lodash';

import { ModalsHash } from 'app-constants';
import useModal from 'components/modals/useModal';
import { useGetFilterSportEvents } from 'gql/betting/queries/getFilterSportEvents.bet';
import { useDeepMemo } from 'hooks';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { useBettingApolloClient } from 'services/apollo/betting';
import { Feature, useFeatureFlags } from 'services/features';
import type {
    Bet,
    GetBetsVariables,
    GetFilterSportEventsVariables,
} from 'types/gql.bet';
import { getArrayValue } from 'utils';
import { betTypeVar } from '../betHistoryState';
import {
    BET_LIST_LIMIT,
    BET_STATUSES_BY_BET_TYPE,
    BetTypeTabs,
} from '../constants';
import { useGetBets } from '../getBets.bet';

import * as Operations from '../getBets.bet.gql';

interface Output {
    betTypeTabs: BetTypeTabs;
    bets: Bet[] | undefined;
    nextPageHandler: (page: number) => Promise<void>;
    isLoading: boolean;
    limit: number;
    openModalHandler: VoidFunction;
}

export default function useBetList(): Output {
    const { client } = useBettingApolloClient();
    const betTypeTabs = useReactiveVar(betTypeVar);
    const { until } = useResponsive();
    const isMobile = until('md');

    const { openModal } = useModal();

    const variables: GetBetsVariables = useDeepMemo(
        () => ({
            input: {
                limit: BET_LIST_LIMIT,
                statuses: [...BET_STATUSES_BY_BET_TYPE[betTypeTabs]],
                offset: 0,
            },
        }),
        [betTypeTabs]
    );

    const {
        data: betsHistory,
        fetchMore,
        loading,
    } = useGetBets(() => ({
        variables,
        fetchPolicy: 'cache-and-network',
    }));

    const { features } = useFeatureFlags();

    const isBetsHistoryBetting =
        isMobile && features.isEnabled(Feature.BetslipBetsHistoryBetting);

    const nextPageHandler = useCallback(
        async (page: number) => {
            const { limit } = variables.input;

            if (limit) {
                await fetchMore({
                    variables: {
                        input: {
                            ...variables.input,
                            offset: (page || 0) * limit,
                        },
                    },
                });
            }
        },
        [variables, fetchMore]
    );

    const openModalHandler = useCallback(
        () =>
            openModal({
                hash: isBetsHistoryBetting
                    ? ModalsHash.MyBetsBetting
                    : ModalsHash.MyBets,
            }),
        [isBetsHistoryBetting, openModal]
    );

    const useGetFilterSportEventsVariables =
        useDeepMemo((): GetFilterSportEventsVariables => {
            return reduce<Bet, GetFilterSportEventsVariables>(
                betsHistory?.bets,
                (acc, bet) => {
                    bet.odds.forEach((betOdd) => {
                        acc.sportEventIds = uniq([
                            ...getArrayValue(acc.sportEventIds),
                            betOdd.sportEvent.id,
                        ]);

                        acc.marketIds = uniq([
                            ...getArrayValue(acc.marketIds),
                            betOdd.market.id,
                        ]);
                    });

                    return acc;
                },
                { sportEventIds: [], marketIds: [] }
            );
        }, [betsHistory?.bets]);

    useGetFilterSportEvents(() => ({
        variables: useGetFilterSportEventsVariables,
        skip: !betsHistory?.bets.length,
    }));

    useEffect(
        () => () => {
            client?.writeQuery({
                query: Operations.GetBets,
                data: { bets: [] },
                variables: {
                    input: {
                        statuses: [...BET_STATUSES_BY_BET_TYPE[betTypeTabs]],
                        // NOTE resetCache custom argument for typePolicy bets
                        resetCache: true,
                    },
                },
            });
        },
        [client, betTypeTabs]
    );

    return {
        betTypeTabs,
        bets: betsHistory?.bets,
        isLoading: loading,
        nextPageHandler,
        limit: variables.input.limit || 0,
        openModalHandler,
    };
}
