import { useEffect, useMemo } from 'react';

import {
    ACTIVE_MARKET_STATUSES,
    ACTIVE_SPORTEVENT_STATUSES,
} from 'app-constants';
import { useBettingLayout } from 'layouts/BettingLayout/BettingLayoutContext';
import { SportEventsInList } from 'types';
import { SportEventOrder, SportEventType } from 'types/gql.bet';
import { useGetSportEventListByFilters } from '../../MatchList/getSportEventListByFilters.bet';

type Output = SportEventsInList[number] | null;

const useRecommendation = (): Output => {
    const { sportIds } = useBettingLayout();

    const variables = useMemo(
        () => ({
            matchStatuses: ACTIVE_SPORTEVENT_STATUSES,
            marketStatuses: ACTIVE_MARKET_STATUSES,
            marketStatusesForSportEvent: ACTIVE_MARKET_STATUSES,
            sportEventTypes: [SportEventType.Match],
            sportIds,
            tournamentIds: [],
            limit: 1,
            offset: 5,
            marketLimit: 1,
            isTopMarkets: true,
            order: SportEventOrder.RankRecommended,
        }),
        [sportIds]
    );

    const { data, refetch } = useGetSportEventListByFilters(() => ({
        fetchPolicy: 'cache-and-network',
        variables,
    }));

    const [sportEvent] = data?.matches.sportEvents || [];

    useEffect(() => {
        if (
            sportEvent &&
            ACTIVE_SPORTEVENT_STATUSES.includes(sportEvent.fixture.status)
        )
            return;

        refetch(variables);
    }, [refetch, sportEvent, variables]);

    return sportEvent || null;
};

export default useRecommendation;
