import React from 'react';
import { floor } from 'lodash';
import { useTranslations } from 'next-intl';

interface Props {
    maxRefundAmount: number;
    currency: string;
}

const Info: React.FC<Props> = ({ maxRefundAmount, currency }) => {
    const t = useTranslations('betting-cashout.cashout.CashOut');

    return (
        <div className="mb-1 flex flex-col gap-2 typo-sm-1">
            <span className="text-grey-500">{t('title')}</span>
            <div className="flex w-full justify-between">
                <span>{t('cashoutAmount')}</span>
                <div className="uppercase text-green-700">
                    {`+${floor(maxRefundAmount, 2)} ${currency}`}
                </div>
            </div>
        </div>
    );
};

export default Info;
