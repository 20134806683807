import type { FC } from 'react';
import { memo } from 'react';
import { useTranslations } from 'next-intl';

import PackIcon, { IconsPack, SvgIconSize } from 'components/PackIcon';

const Warning: FC = () => {
    const t = useTranslations('betting-cashout.cashout.Cashout');

    return (
        <div className="flex gap-1 break-words rounded-[4px] border-default border-yellow-700 p-2 text-yellow-700 typo-sm-1">
            <PackIcon
                id="warning"
                size={SvgIconSize.WH_4}
                className="fill-yellow-700"
                pack={IconsPack.SpriteIcons}
            />
            <div className="flex flex-1 items-center">
                {t('cashOutRefundAmount')}
            </div>
        </div>
    );
};

export default memo(Warning);
