import React, { createContext, PropsWithChildren, useMemo } from 'react';

import { useToggle } from 'hooks';

interface IBetFooterContext {
    isCashOutOpen: boolean;
    toggleCashoutHandler: VoidFunction;
}

export const BetFooterContext = createContext<IBetFooterContext>({
    isCashOutOpen: false,
    toggleCashoutHandler: () => {},
});

const BetFooterProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [isCashOutOpen, toggleCashoutHandler] = useToggle();

    const value = useMemo(
        () => ({
            isCashOutOpen,
            toggleCashoutHandler,
        }),
        [isCashOutOpen, toggleCashoutHandler]
    );

    return (
        <BetFooterContext.Provider value={value}>
            {children}
        </BetFooterContext.Provider>
    );
};

export default BetFooterProvider;
