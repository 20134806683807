import { FC, memo } from 'react';

import withClientMessages from 'hocs/withClientMessages';
import BetList from './BetList';
import BetListHeader from './BetListHeader';

const BetHistory: FC = () => {
    return (
        <>
            <div className="relative flex h-full flex-col">
                <BetListHeader />
                <BetList />
            </div>
            <div id="bet-outcomes-portal" />
        </>
    );
};

export default memo(
    withClientMessages(BetHistory, [
        'betting-betshistory',
        'betting-cashout',
        'BetsHistory',
    ])
);
