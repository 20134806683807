import type { FC } from 'react';
import { memo, useState } from 'react';
import { isEmpty, map, values } from 'lodash';

import { Odd, PlaceCashOutSelection } from 'types/gql.bet';
import idPathParser from 'utils/idPathParser';
import { useBetItemData } from '../BetsHistory/BetItem/BetItemProvider';
import CashOut from './CashOut';
import CashOutSubscriptionOdd from './CashOutSubscriptionOdd';

interface MakeHandleUpdateInput {
    path: string;
    value: string;
    variables: {
        sportEventId: string;
        marketId: string;
        oddId: string;
    };
}

const CashOutProvider: FC = () => {
    const { odds, totals, betId } = useBetItemData();
    const [selectionMap, setSelectionMap] = useState<
        Record<string, PlaceCashOutSelection>
    >({});

    const { totalStake } = totals;

    const makeHandleUpdate = ({
        path,
        variables,
        value,
    }: MakeHandleUpdateInput) => {
        return ({ odd }: { path: string; odd: Odd | undefined }): void => {
            const newSelection = {
                ...variables,
                value: Number(odd?.value || value),
            };

            if (selectionMap[path]?.value === newSelection.value) {
                return;
            }

            setSelectionMap((p) => ({
                ...p,
                [path]: newSelection,
            }));
        };
    };

    return (
        <>
            {map(odds, (betOdd) => {
                const variables = {
                    sportEventId: betOdd.sportEvent.id,
                    marketId: betOdd.market.id,
                    oddId: betOdd.odd.id,
                };

                const path = idPathParser.join('Odd', variables);

                return (
                    <CashOutSubscriptionOdd
                        key={path}
                        path={path}
                        onUpdate={makeHandleUpdate({
                            path,
                            variables,
                            value: betOdd.odd.value,
                        })}
                    />
                );
            })}
            {!isEmpty(selectionMap) && (
                <CashOut
                    betId={betId}
                    amount={totalStake}
                    selections={values(selectionMap)}
                />
            )}
        </>
    );
};

export default memo(CashOutProvider);
