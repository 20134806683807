import type { FC } from 'react';
import { memo } from 'react';

import { Error, Success, Warning } from '../CashOutMessages';

interface Props {
    currency: string;
    errors: string[];
    isAccepted: boolean;
    isAmountChanged: boolean;
    isDeclined: boolean;
    maxRefundAmount: number;
}

const Footer: FC<Props> = ({
    errors,
    isAccepted,
    isAmountChanged,
    isDeclined,
    currency,
    maxRefundAmount,
}) => (
    <div className="flex flex-col gap-2">
        {isAccepted && (
            <Success maxRefundAmount={maxRefundAmount} currency={currency} />
        )}
        {isDeclined && <Error errors={errors} />}
        {isAmountChanged && <Warning />}
    </div>
);

export default memo(Footer);
