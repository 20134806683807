import React, { FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { useReactiveVar } from '@apollo/client';
import cn from 'classnames';
import { map } from 'lodash';

import { ACTIVE_SPORTEVENT_STATUSES } from 'app-constants';
import { Bet as BetHistoryType, BetType } from 'types/gql.bet';
import idPathParser from 'utils/idPathParser';
import { betTypeVar } from '../betHistoryState';
import { BetTypeTabs, RefundStatus } from '../constants';
import BetFooter from './BetFooter';
import BetHeader from './BetHeader';
import BetItemProvider, { useBetItemData } from './BetItemProvider';
import BetItemSportEventSubscription from './BetItemSportEventSubscription';
import Odd from './Odd';

interface Props {
    bet: BetHistoryType;
}

const BetItem: FC<Props> = ({ bet }) => {
    return (
        <BetItemProvider bet={bet}>
            <Bet />
        </BetItemProvider>
    );
};

const Bet: React.FC = () => {
    const [ref, isInView] = useInView();
    const { refundInfo, freebetId, betType, odds, insurance } =
        useBetItemData();

    const { refundStatus } = refundInfo;
    const isRefundStatusLoss = refundStatus === RefundStatus.Loss;

    const betTabType = useReactiveVar(betTypeVar);

    const { status } = odds[0].sportEvent.fixture;

    const isCorrectMatchStatus = ACTIVE_SPORTEVENT_STATUSES.includes(status);

    const isCashouted =
        freebetId === '' &&
        !insurance &&
        betType === BetType.Single &&
        betTabType !== BetTypeTabs.Settled &&
        isInView;

    return (
        <>
            {!isCashouted &&
                isInView &&
                isCorrectMatchStatus &&
                map(odds, (betOdd) => {
                    const variables = {
                        sportEventId: betOdd.sportEvent.id,
                        marketId: betOdd.market.id,
                        oddId: betOdd.odd.id,
                    };

                    const path = idPathParser.join('Odd', variables);

                    return (
                        <BetItemSportEventSubscription key={path} path={path} />
                    );
                })}
            <div
                ref={ref}
                className={cn(
                    'mt-0 rounded-default bg-surface-middle typo-xs-1',
                    isRefundStatusLoss ? 'text-grey-700' : 'text-primary-white'
                )}
            >
                <BetHeader />
                <Odd />
                <BetFooter isCashouted={isCashouted} />
            </div>
        </>
    );
};

export default BetItem;
