import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { isNil, last, reduce } from 'lodash';

import { useGetCalculateCashOut } from 'components/betting/CashOut/getCalculateCashOut.bet';
import { usePlaceCashOutOrder } from 'components/betting/CashOut/placeCashOutOrder.bet';
import betFragment from 'gql/betting/fragments/betFragment.bet.gql';
import { useDeepMemo, useFragment, useMounted } from 'hooks';
import { useBettingApolloClient } from 'services/apollo';
import {
    Bet,
    BetOdd,
    CashOutOrderStatusCode,
    CashOutRestrictions,
    MarketStatus,
    OddStatus,
    PlaceCashOutSelection,
    SportEventStatus,
} from 'types/gql.bet';
import { BetFooterContext } from '../BetsHistory/BetItem/BetFooter/context';

const AmountChangedInterval = 8000;

interface Input {
    betId: string;
    amount: number;
    selections: PlaceCashOutSelection[];
}

interface Output {
    placeCashOut: VoidFunction;
    amount: number | null;
    isLoading: boolean;
    status: CashOutOrderStatusCode | null;
    maxRefundAmount: number | null;
    restrictions: CashOutRestrictions[];
    isAmountChanged: boolean;
    isOpen: boolean;
    handleToggleCashout: VoidFunction;
    currencyCode: string;
}

const useCashOutBase = ({ betId, amount, selections }: Input): Output => {
    const [restrictions, setRestrictions] = useState<CashOutRestrictions[]>([]);
    const { isCashOutOpen, toggleCashoutHandler } =
        useContext(BetFooterContext);

    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const isMounted = useMounted();

    const [isAmountChanged, setIsAmountChanged] = useState<boolean>(false);
    const [maxRefundAmount, setMaxRefundAmount] = useState<number | null>(null);

    const memoVariables = useMemo<{
        betId: string;
        amount: number;
        selections: PlaceCashOutSelection[];
    }>(
        () => ({
            betId,
            amount,
            selections,
        }),
        [betId, amount, selections]
    );

    const prevMaxRefundAmountRef = useRef<number | null>(null);

    const { loading, refetch } = useGetCalculateCashOut(() => ({
        skip: !memoVariables,
        variables: memoVariables,
        onCompleted({ calculateCashOut }) {
            const maxRefundAmountNew = +calculateCashOut.amount.maxRefundAmount;
            const isAmountChangedNew =
                !isNil(maxRefundAmountNew) &&
                !isNil(prevMaxRefundAmountRef.current) &&
                prevMaxRefundAmountRef.current !== maxRefundAmountNew;

            prevMaxRefundAmountRef.current = maxRefundAmountNew;
            setIsAmountChanged(isAmountChangedNew);

            setRestrictions(calculateCashOut.restrictions);
            setMaxRefundAmount(maxRefundAmountNew);
        },
    }));

    const [placeCashOutMutation] = usePlaceCashOutOrder({
        onCompleted({ placeCashOutOrder: { context } }) {
            if (!isMounted) return;

            setRestrictions(context.restrictions);
        },
    });

    const placeCashOut = useCallback(() => {
        if (!memoVariables || !maxRefundAmount) return;

        placeCashOutMutation({
            variables: {
                ...memoVariables,
                refundAmount: +maxRefundAmount,
            },
        });
    }, [maxRefundAmount, memoVariables, placeCashOutMutation]);

    const { client } = useBettingApolloClient();

    const { data: betData } = useFragment<Bet>({
        id: betId,
        fragment: betFragment,
        typename: 'Bet',
        client,
    });

    const status = last(betData?.cashOutOrders)?.status.code || null;

    const statuses = useDeepMemo(
        reduce<
            BetOdd,
            {
                market: MarketStatus[];
                sportEvent: SportEventStatus[];
                odd: OddStatus[];
            }
        >(
            betData?.odds,
            (acc, { market, sportEvent, odd }) => {
                if (market.status) acc.market.push(market.status);

                if (sportEvent.fixture.status)
                    acc.sportEvent.push(sportEvent.fixture.status);

                if (odd.status) acc.odd.push(odd.status);

                return acc;
            },
            { market: [], sportEvent: [], odd: [] }
        )
    );

    const handleToggleCashout = useCallback(() => {
        toggleCashoutHandler();

        if (isCashOutOpen) {
            setIsAmountChanged(false);

            return;
        }

        refetch();
    }, [isCashOutOpen, refetch, toggleCashoutHandler]);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statuses]);

    useEffect(() => {
        if (isAmountChanged) {
            timerRef.current = setTimeout(() => {
                setIsAmountChanged(false);
                prevMaxRefundAmountRef.current = maxRefundAmount
                    ? Number(maxRefundAmount)
                    : null;
            }, AmountChangedInterval);
        }

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [isAmountChanged, maxRefundAmount]);

    return {
        placeCashOut,
        amount: memoVariables?.amount ?? null,
        isLoading: loading,
        status,
        maxRefundAmount: maxRefundAmount ? +maxRefundAmount : null,
        restrictions,
        isOpen: isCashOutOpen,
        handleToggleCashout,
        isAmountChanged,
        currencyCode: betData?.currencyCode || '',
    };
};

export default useCashOutBase;
