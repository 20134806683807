import { FC, memo, useEffect } from 'react';

import oddFragment from 'gql/betting/fragments/oddFragment.bet.gql';
import { useOnUpdateSportEvent } from 'gql/betting/subscriptions/onUpdateSportEvent.bet';
import { useFragment } from 'hooks';
import { useBettingApolloClient } from 'services/apollo';
import { Odd } from 'types/gql.bet';
import idPathParser from 'utils/idPathParser';

interface Props {
    path: string;
    onUpdate: (udate: { path: string; odd: Odd | undefined }) => void;
}

const CashOutSubscriptionOdd: FC<Props> = ({ path, onUpdate }) => {
    const { client } = useBettingApolloClient();

    const { data: oddData } = useFragment<Odd>({
        id: path,
        fragment: oddFragment,
        typename: 'Odd',
        client,
    });

    const { sportEventId, marketId } = idPathParser.split('Odd', path);

    useOnUpdateSportEvent({
        variables: { sportEventId, marketIds: [marketId] },
    });

    useEffect(() => {
        onUpdate({ path, odd: oddData });
    }, [onUpdate, path, oddData]);

    return null;
};

export default memo(CashOutSubscriptionOdd);
