import { type FC, Fragment } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslations } from 'next-intl';

import { ModalsHash } from 'app-constants';
import Empty from 'components/Empty';
import { useRouter } from 'components/HybridRouter';
import Spinner from 'components/Spinner';
import { Feature, useFeatureFlags } from 'services/features';
import Pagination from '../../Pagination';
import BetItem from '../BetItem';
import { BetTypeTabs } from '../constants';
import Recommendation from '../Recommendation';
import useBetList from './useBetList';

const SPORT_EVENT_POSITION = 0 as const;

const BetList: FC = () => {
    const {
        betTypeTabs,
        nextPageHandler,
        bets,
        isLoading,
        limit,
        openModalHandler,
    } = useBetList();
    const t = useTranslations('betting-betshistory.betsHistory');
    const { asPathObj } = useRouter();

    const isBetsHistoryRout = asPathObj?.hash === ModalsHash.MyBetsBetting;
    const { features } = useFeatureFlags();

    if (isLoading)
        return (
            <Spinner className="absolute left-2/4 top-1/4 -translate-x-1/2" />
        );

    if (!bets) return null;

    const isActiveBets = betTypeTabs === BetTypeTabs.Unsettled;

    const isBetsHistoryRecommendation = features.isEnabled(
        Feature.BetsHistoryRecommendation
    );

    return (
        <>
            <div className="h-full flex-1 py-2" data-test="bet-list">
                {bets.length > 0 && (
                    <Scrollbars
                        hideTracksWhenNotNeeded={true}
                        universal={true}
                        renderView={(props) => (
                            <div
                                {...props}
                                className="flex flex-col gap-2 p-2"
                            />
                        )}
                    >
                        <Pagination
                            limitPerPage={limit}
                            onNextPage={nextPageHandler}
                            lengthList={bets.length}
                            spinner={<Spinner />}
                        >
                            {bets.map((bet, index) => (
                                <Fragment key={bet.id}>
                                    <BetItem bet={bet} />
                                    {betTypeTabs === BetTypeTabs.Settled &&
                                        index === SPORT_EVENT_POSITION &&
                                        isBetsHistoryRecommendation && (
                                            <Recommendation />
                                        )}
                                </Fragment>
                            ))}
                        </Pagination>
                    </Scrollbars>
                )}
                {bets.length === 0 && (
                    <Empty
                        icon="/spa-assets/image/empty-state/bets-chicken.png"
                        title={t('Empty.emptyTitle')}
                        message={
                            isActiveBets
                                ? t('Empty.emptyUnsettled')
                                : t('Empty.emptySettled')
                        }
                    />
                )}
            </div>
            {!isBetsHistoryRout && (
                <div
                    onClick={openModalHandler}
                    className="flex h-14 cursor-pointer items-center justify-center bg-surface-middle text-surface-white-dark typo-sm-2"
                >
                    {t('BetsHistory.allBetsText')}
                </div>
            )}
        </>
    );
};

export default BetList;
